import { useContext } from "react";

import { Button, Icon } from "components/common/basic";
import { Banner } from "components/common/composite";
import { SubscriptionFragment } from "graphql/fragments";
import { useUpdateSubscriptionMutation } from "graphql/mutations";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { atDateToMoment, getProductName } from "utils";
import { tw } from "utils/tw";

export default ({
  tier: { interval, amount, currency, memberLimit, memberUsage, ...tier },
  currentPeriodStartAt,
  currentPeriodEndAt,
  downgradeTier,
  downgradedAt,
}: SubscriptionFragment): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);
  const { updateSubscription, isLoading } = useUpdateSubscriptionMutation();

  return (
    <div
      className={tw(
        "w-full",
        "max-w-xl",
        "bg-white",
        "shadow-md",
        "rounded-lg",
        "overflow-hidden"
      )}
    >
      <div className={tw("p-6", "bg-silver", "space-y-2")}>
        <div
          className={tw(
            "flex",
            "flex-col",
            "gap-1",
            "md:flex-row",
            "md:justify-between",
            "md:items-center"
          )}
        >
          <h2 className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
            {getProductName(tier.type, t)}
          </h2>

          <p className={tw("text-deepBlue-900", "font-bold", "md:text-lg")}>
            {t(
              `accountSettings_org.subscriptions.subscription_${interval.toLowerCase()}.price`,
              `${interval} {{ price }},- {{ currency }}*`,
              { price: amount / 100, currency }
            )}
          </p>
        </div>

        <p className={tw("text-deepBlue-900")}>
          {t(
            "accountSettings_org.subscriptions.subscription.period",
            "Subscription period: {{ from }} - {{ to }}",
            {
              from: atDateToMoment(currentPeriodStartAt).format("DD MMM YYYY"),
              to: atDateToMoment(currentPeriodEndAt).format("DD MMM YYYY"),
            }
          )}
        </p>

        <button
          className={tw("underline", "text-deepBlue-900", "text-sm")}
          onClick={() =>
            openPopup({
              id: "UpdateSubscription",
              props: {},
            })
          }
        >
          {t(
            "accountSettings_org.subscriptions.subscription.viewDetails",
            "View details"
          )}
        </button>
      </div>

      <div
        className={tw(
          "p-6",
          "pt-8",
          "flex",
          "flex-col",
          "space-y-8",
          "items-start"
        )}
      >
        <div
          className={tw(
            "w-full",
            "p-4",
            "space-y-2",
            "rounded-lg",
            "border",
            "border-deepBlue-50"
          )}
        >
          <div className={tw("flex", "items-center", "space-x-2")}>
            <Icon icon="FaUsers" isFilled size={24} />

            <p className={tw("text-deepBlue-900", "font-bold")}>
              {t(
                "accountSettings_org.subscriptions.subscription.details.limit",
                {
                  count: memberLimit ?? 0,
                  defaultValue_zero: "Unlimited team members included",
                  defaultValue_one: "{{ count }} team member included",
                  defaultValue_other: "{{ count }} team members included",
                }
              )}
            </p>
          </div>

          <p className={tw("text-blue-900")}>
            {memberLimit ? (
              <Trans
                ns="account"
                i18nKey="accountSettings_org.subscriptions.subscription.details.usage"
                defaults="You have added <0>{{ usage }}</0> of <0>{{ limit }}</0> team members"
                components={[<strong className={tw("font-bold")} />]}
                values={{ usage: memberUsage, limit: memberLimit }}
              />
            ) : (
              <Trans
                ns="account"
                i18nKey="accountSettings_org.subscriptions.subscription.details.usage.unlimited"
                defaults="You have added <0>{{ usage }}</0> team members"
                components={[<strong className={tw("font-bold")} />]}
                values={{ usage: memberUsage }}
              />
            )}
          </p>
        </div>

        {memberLimit && memberUsage && memberUsage >= memberLimit && (
          <Banner
            variant="info"
            title={t(
              "accountSettings_org.subscriptions.subscription.maxUsageBanner.title",
              "You have maxed out your subscription plan"
            )}
            body={t(
              "accountSettings_org.subscriptions.subscription.maxUsageBanner.body",
              "Upgrade to a higher subscription plan to add more team members and benefit from more features."
            )}
          />
        )}

        {downgradedAt && (
          <Banner
            variant="danger"
            title={t(
              "accountSettings_org.subscriptions.subscription.downgradeBanner.title",
              "Downgrading"
            )}
            body={t(
              "accountSettings_org.subscriptions.subscription.downgradeBanner.body",
              "Your team account is scheduled for a downgrade from {{ from }} to {{ to }} on: {{ date }}\nAt that time, you and your team will lose access to certain premium features. Please review your subscription to avoid any interruptions.",
              {
                from: getProductName(tier.type, t),
                to: getProductName(downgradeTier.type, t),
                date: atDateToMoment(downgradedAt).format("DD MMM YYYY"),
              }
            )}
            rightColumn={
              <Button
                id="check_more_request-include_duplicates"
                variant="tertiary"
                size="sm"
                onClick={() =>
                  openPopup({
                    id: "ConfirmAction",
                    props: {
                      onConfirmation: () => updateSubscription(tier.id),
                      confirmationLabel: t(
                        "accountSettings_org.subscriptions.subscription.cancelDowngradeConfirmation.label",
                        "Continue on the {{ plan }} plan",
                        { plan: getProductName(tier.type, t) }
                      ),
                      confirmationHeading: t(
                        "accountSettings_org.subscriptions.subscription.cancelDowngradeConfirmation.heading",
                        "Are you sure you want to continue on the {{ plan }} plan?",
                        { plan: getProductName(tier.type, t) }
                      ),
                      confirmationBody: t(
                        "accountSettings_org.subscriptions.subscription.cancelDowngradeConfirmation.body",
                        "This action is not reversible"
                      ),
                    },
                  })
                }
                disabled={isLoading}
              >
                {t(
                  "accountSettings_org.subscriptions.subscription.downgradeBanner.button",
                  "Cancel downgrade"
                )}
              </Button>
            }
          />
        )}

        <Button
          id="orgAccountSettings_subscriptions-upgradeSubscription"
          onClick={() =>
            openPopup({
              id: "UpdateSubscription",
              props: {},
            })
          }
        >
          {t(
            "accountSettings_org.subscriptions.subscription.upgrade",
            "Upgrade plan"
          )}
        </Button>

        <p className={tw("text-deepBlue-900", "text-sm")}>
          {t(
            "accountSettings_org.subscriptions.subscription.vatInfo",
            "*VAT will be added if applicable."
          )}
        </p>

        {/* <button
          className={tw("underline", "text-deepBlue-900", "text-sm")}
          onClick={() =>
            openPopup({
              id: "CancelSubscription",
              props: {},
            })
          }
        >
          {t(
            "accountSettings_org.subscriptions.subscription.cancel",
            "Cancel subscription"
          )}
        </button> */}
      </div>
    </div>
  );
};
