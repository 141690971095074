import { useContext } from "react";
import {
  HiOutlineArchive,
  HiOutlinePaperAirplane,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineXCircle,
} from "react-icons/hi";

import {
  MenuItemAction,
  MenuItemLink,
} from "components/common/wrappers/Layout/common/MenuList/types";
import { DocumentFragment } from "graphql/fragments";
import {
  useArchiveDocumentsMutation,
  useDestroyDocumentsMutation,
  useNotifyDocumentsMutation,
  useUnarchiveDocumentsMutation,
} from "graphql/mutations";
import { DocumentStatusEnum, FeatureTypeEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";

export const useDocumentActions = () => {
  const { t } = useTranslation("documents");
  const getRoutePath = useRoutePaths();
  const { notifyDocuments } = useNotifyDocumentsMutation();
  const { destroyDocuments } = useDestroyDocumentsMutation();
  const { archiveDocuments } = useArchiveDocumentsMutation();
  const { unarchiveDocuments } = useUnarchiveDocumentsMutation();

  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  const hasArchiveFeature = Boolean(
    session?.account.subscription?.tier.features.includes(
      FeatureTypeEnum.Archive
    )
  );
  const upgrade = () =>
    openPopup({
      id: "UpgradeRequired",
      props: { feature: FeatureTypeEnum.Archive },
    });

  const archivalAction = (document: DocumentFragment) =>
    document.archivedAt
      ? {
          id: "document_actions-unarchive" as const,
          label: t("document.action.unarchive", "Unarchive"),
          Icon: HiOutlineArchive,
          onClick: () =>
            !hasArchiveFeature
              ? upgrade()
              : unarchiveDocuments({ ids: [document.id] }),
        }
      : {
          id: "document_actions-archive" as const,
          label: t("document.action.archive", "Archive"),
          Icon: HiOutlineArchive,
          onClick: () =>
            !hasArchiveFeature
              ? upgrade()
              : archiveDocuments({ ids: [document.id] }),
        };
  const editAction = (documentId: string) => ({
    id: "document_actions-go_to_draft" as const,
    label: t("document.action.editDraft", "Continue editing"),
    Icon: HiOutlinePencil,
    to: getRoutePath({
      module: "signmore",
      routeName: "CREATE_DOCUMENT_WIZARD",
      arg1: documentId,
    }),
  });
  const deleteAction = (documentId: string) => ({
    id: "document_actions-delete" as const,
    Icon: HiOutlineTrash,
    label: t("documents.table.action.delete", "Delete"),
    onClick: () =>
      openPopup({
        id: "ConfirmAction",
        props: {
          onConfirmation: () => destroyDocuments([documentId]),
          confirmationLabel: t(
            "documents.table.deleteConfirmation.label",
            "Delete"
          ),
          confirmationHeading: t(
            "documents.table.deleteConfirmation.heading",
            "Are you sure you want to delete this document?"
          ),
          confirmationBody: t(
            "documents.table.deleteConfirmation.body",
            "This action is not reversible"
          ),
          isDestructive: true,
        },
      }),
    isDestructive: true,
  });
  const remindAllAction = (documentId: string) => ({
    id: "document_actions-remind" as const,
    label: t("document.action.remindAll", "Send reminder"),
    Icon: HiOutlinePaperAirplane,
    onClick: () => notifyDocuments({ ids: [documentId] }),
  });
  const cancelAction = (document: DocumentFragment) => ({
    id: "document_actions-cancel" as const,
    label: t("document.action.cancel", "Cancel"),
    Icon: HiOutlineXCircle,
    onClick: () =>
      openPopup({
        id: "CancelDocuments",
        props: { ids: [document.id] },
      }),
    isDestructive: true,
  });

  const getDocumentActions = (
    document: DocumentFragment
  ): (MenuItemLink | MenuItemAction)[] => {
    switch (document.status) {
      case DocumentStatusEnum.Draft:
        return [
          editAction(document.id),
          archivalAction(document),
          deleteAction(document.id),
        ];

      case DocumentStatusEnum.Pending:
        return [
          ...(document.owned ? [remindAllAction(document.id)] : []),
          ...(document.owned ? [cancelAction(document)] : []),
        ];

      case DocumentStatusEnum.Expired:
      case DocumentStatusEnum.Refused:
        return [archivalAction(document), deleteAction(document.id)];

      case DocumentStatusEnum.Signed:
      case DocumentStatusEnum.Cancelled:
        return [archivalAction(document), deleteAction(document.id)];

      default:
        return ((_status: null | undefined) => [])(document.status);
    }
  };

  return { getDocumentActions };
};
