import { useContext, useEffect, useState } from "react";

import Cookie from "js-cookie";

import { Banner } from "components/common/composite";
import { TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { atDateToMoment, getParsedJSON, useRoutePaths } from "utils";
import { tw } from "utils/tw";

interface BannerStatuses {
  showMemberLimit: boolean;
  showSignatureLimit: boolean;
  showDocuCheckLimit: boolean;
  showIDCheckLimit: boolean;
}

const defaultBannerStatuses = {
  showMemberLimit: true,
  showSignatureLimit: true,
  showDocuCheckLimit: true,
  showIDCheckLimit: true,
};

export default (): JSX.Element | null => {
  const [bannerStatuses, setBannerStatuses] = useState<BannerStatuses>(
    defaultBannerStatuses
  );

  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);
  const { t } = useTranslation("common");
  const getRoutePath = useRoutePaths();

  const tier = session?.account.subscription?.tier;
  const hasReachedMemberLimit =
    (tier?.memberUsage &&
      tier?.memberLimit &&
      tier.memberUsage >= tier.memberLimit) ||
    null;

  const { eSignature, docuCheck, nordicIdCheck } = tier?.products ?? {};
  const hasReachedSignatureLimit =
    (eSignature?.usage &&
      eSignature?.limit &&
      eSignature.usage >= eSignature.limit) ||
    null;
  const hasReachedDocuCheckLimit =
    (docuCheck?.usage &&
      docuCheck?.limit &&
      docuCheck.usage >= docuCheck.limit) ||
    null;
  const hasReachedIDCheckLimit =
    (nordicIdCheck?.usage &&
      nordicIdCheck?.limit &&
      nordicIdCheck.usage >= nordicIdCheck.limit) ||
    null;

  // Get initial bannerStatuses from cookie
  useEffect(() => {
    const bannerCookie = Cookie.get("mm-banners");
    const parsedCookie = getParsedJSON(bannerCookie);

    const showMemberLimit =
      (parsedCookie as BannerStatuses | null)?.showMemberLimit ?? true;
    const showSignatureLimit =
      (parsedCookie as BannerStatuses | null)?.showSignatureLimit ?? true;
    const showDocuCheckLimit =
      (parsedCookie as BannerStatuses | null)?.showDocuCheckLimit ?? true;
    const showIDCheckLimit =
      (parsedCookie as BannerStatuses | null)?.showIDCheckLimit ?? true;

    setBannerStatuses({
      showMemberLimit,
      showSignatureLimit,
      showDocuCheckLimit,
      showIDCheckLimit,
    });
  }, []);

  // Set banners to shown if the limits are no longer reached
  useEffect(() => {
    if (!hasReachedMemberLimit && bannerStatuses.showMemberLimit === false)
      setBannerStatus("showMemberLimit", true);

    if (
      !hasReachedSignatureLimit &&
      bannerStatuses.showSignatureLimit === false
    )
      setBannerStatus("showSignatureLimit", true);

    if (
      !hasReachedDocuCheckLimit &&
      bannerStatuses.showDocuCheckLimit === false
    )
      setBannerStatus("showDocuCheckLimit", true);

    if (!hasReachedIDCheckLimit && bannerStatuses.showIDCheckLimit === false)
      setBannerStatus("showIDCheckLimit", true);
  }, [
    hasReachedMemberLimit,
    hasReachedSignatureLimit,
    hasReachedDocuCheckLimit,
    hasReachedIDCheckLimit,
  ]);

  if (!session?.account.subscription) return null;

  const upgrade = () => openPopup({ id: "UpdateSubscription", props: {} });
  const upgradeButton = (
    <button
      onClick={upgrade}
      className={tw("underline", "text-deepBlue-900", "hover:opacity-90")}
    />
  );

  const setBannerStatus = (
    bannerKey: keyof BannerStatuses,
    status: boolean
  ) => {
    const updatedBannerStatuses = (
      Object.keys(bannerStatuses) as (keyof BannerStatuses)[]
    ).reduce(
      (acc: BannerStatuses, statusKey) => ({
        ...acc,
        [statusKey]:
          statusKey === bannerKey ? status : bannerStatuses[statusKey],
      }),
      bannerStatuses
    );

    setBannerStatuses(updatedBannerStatuses);
    Cookie.set("mm-banners", JSON.stringify(updatedBannerStatuses));
  };

  const isAdminOrOwner =
    session.role === TeamRoleEnum.Admin || session.role === TeamRoleEnum.Owner;
  const downgradedAt = session.account.subscription.downgradedAt;
  const isOutdated = typeof Promise.withResolvers === "undefined";

  return (
    <div className={tw("flex", "flex-col", "gap-px")}>
      {downgradedAt && (
        <Banner
          rounded={false}
          variant="warning"
          body={t(
            "bannerSection.downgrading.body",
            "Your team account is scheduled for a downgrade on: {{ date }}",
            { date: atDateToMoment(downgradedAt).format("DD MMM YYYY") }
          )}
          actions={
            isAdminOrOwner
              ? [
                  {
                    id: "banner_section-go_to_read_more",
                    label: t("bannerSection.downgrading.readMore", "Read more"),
                    to: getRoutePath({
                      module: "account",
                      routeName: "SETTINGS_SUBSCRIPTIONS",
                    }),
                  },
                ]
              : undefined
          }
        />
      )}

      {isOutdated && (
        <Banner
          rounded={false}
          variant="warning"
          title={t(
            "bannerSection.outdated.title",
            "Looks like you are using an outdated browser"
          )}
          body={t(
            "bannerSection.outdated.body",
            "Manymore functions best on the newest browser versions, and we recommend that you update your browser."
          )}
        />
      )}

      {hasReachedMemberLimit && bannerStatuses.showMemberLimit && (
        <Banner
          rounded={false}
          verticallyCentered
          variant="info"
          body={
            <p>
              <Trans
                ns="common"
                i18nKey="bannerSection.reachedMemberLimit.body"
                defaults="You have all filled up all your seats for this subscription! <0>Upgrade to the next tier</0>"
                components={[upgradeButton]}
              />
            </p>
          }
          onClose={() => setBannerStatus("showMemberLimit", false)}
        />
      )}

      {hasReachedSignatureLimit && bannerStatuses.showSignatureLimit && (
        <Banner
          rounded={false}
          verticallyCentered
          variant="info"
          body={
            <p>
              <Trans
                ns="common"
                i18nKey="bannerSection.reachedSignatureLimit.body"
                defaults="You have used up all your free e-signatures for this month! <0>Upgrade to the next tier</0> or pay as you go"
                components={[upgradeButton]}
              />
            </p>
          }
          onClose={() => setBannerStatus("showSignatureLimit", false)}
        />
      )}

      {hasReachedDocuCheckLimit && bannerStatuses.showDocuCheckLimit && (
        <Banner
          rounded={false}
          verticallyCentered
          variant="info"
          body={
            <p>
              <Trans
                ns="common"
                i18nKey="bannerSection.reachedDocuCheckLimit.body"
                defaults="You have used up all your free docuchecks for this month! <0>Upgrade to the next tier</0> or pay as you go"
                components={[upgradeButton]}
              />
            </p>
          }
          onClose={() => setBannerStatus("showDocuCheckLimit", false)}
        />
      )}

      {hasReachedIDCheckLimit && bannerStatuses.showIDCheckLimit && (
        <Banner
          rounded={false}
          verticallyCentered
          variant="info"
          body={
            <p>
              <Trans
                ns="common"
                i18nKey="bannerSection.reachedIdentityCheckLimit.body"
                defaults="You have used up all your free identity checks for this month! <0>Upgrade to the next tier</0> or pay as you go"
                components={[upgradeButton]}
              />
            </p>
          }
          onClose={() => setBannerStatus("showIDCheckLimit", false)}
        />
      )}
    </div>
  );
};
